<template>
    <OAutocomplete :id="autocompleteId" v-if="autocompleteDataObject" :dataObject="autocompleteDataObject" :value="filterItem.expressionValue ?? filterItem.selectedValue" 
        :field="autocompleteDisplayField" class="text-truncate d-flex flex-1" wrapperClass="text-truncate d-flex flex-1" :filterOperator="autocompleteFilterOperator"
        :bind="onSelected" :disableFirstItemAutoSelect="!existingValuesOnly" :disableCloseOnEnter="existingValuesOnly" @enter:input="onRawEnter" @blur:noSelection="onBlur"
        :data-bs-title="existingValuesOnly ? $t('Only existing values allowed') : undefined">
        <template #default="{row}">
            {{row[autocompleteDisplayField]}}
        </template>
    </OAutocomplete>
    <OAutocomplete :id="autocompleteId" v-else :value="filterItem.expressionValue ?? filterItem.selectedValue" field="display" class="text-truncate d-flex flex-1" wrapperClass="text-truncate d-flex flex-1" 
        :bind="onSelected" :getData="getData" :disableFirstItemAutoSelect="!existingValuesOnly" :disableCloseOnEnter="existingValuesOnly" @enter:input="onRawEnter" @blur:noSelection="onBlur"
        :data-bs-title="existingValuesOnly ? $t('Only existing values allowed') : undefined">
        <template #default="{row}">
            {{row.display}}
        </template>
    </OAutocomplete>
</template>

<script setup lang="ts">
import type FilterItem from 'o365.modules.FilterItem.ts';
import type FilterObject from 'o365.modules.FilterObject.ts';

import OAutocomplete from 'o365.vue.components.Autocomplete.vue';
import { getDataObjectById } from 'o365.vue.ts';
import $t from 'o365.modules.translate.ts';
import logger from 'o365.modules.Logger.ts';
import { computed } from 'vue';

const props = defineProps<{
    filterItem: FilterItem
    filterObject: FilterObject
}>();

const uid = window.crypto.randomUUID();

const existingValuesOnly = computed(() => props.filterItem?.options?.filterParams?.autocomplete?.existingValuesOnly ?? false);
const autocompleteId = computed(() => `${props.filterObject.dataObject.id}_${props.filterItem?.options?.name}-${uid}`);
const autocompleteFilterOperator = computed(() => props.filterItem?.options?.filterParams?.autocomplete?.filterOperator ?? undefined);
const autocompleteField = computed(() => props.filterItem?.options?.filterParams?.autocomplete?.field);
const autocompleteDisplayField = computed(() => props.filterItem?.options?.filterParams?.autocomplete?.displayField ?? autocompleteField.value);
const autocompleteValueField = computed(() => props.filterItem?.options?.filterParams?.autocomplete?.valueField ?? autocompleteField.value);
const autocompleteDataObject = computed(() => {
    const dataObjectId = props.filterItem?.options?.filterParams?.autocomplete?.dataObjectId;
    const appId = props.filterItem?.options?.filterParams?.autocomplete?.appId;
    if (dataObjectId && appId) {
        return getDataObjectById(dataObjectId, appId)
    } 
});

function onBlur(_pEvent, pValue, pSelectableValue) {
    if (pSelectableValue != null) {
        onSelected(pSelectableValue);
        return;
    } 
    if (existingValuesOnly.value && pValue) { return; }
    props.filterItem.useAlias = false;
    props.filterItem.selectedValue = pValue;
    props.filterItem.expressionValue = pValue;
    // props.filterItem.operator = props.filterItem.defaultOperator;
    props.filterObject.apply();
}

let tooltipDebounce: number | null = null;
function onRawEnter(_pEvent, pValue) {
    if (existingValuesOnly.value && pValue) { 
        window.requestAnimationFrame(() => {
            try {
                const inputEl = document.getElementById(autocompleteId.value);
                const tooltip = window.bootstrap.Tooltip.getOrCreateInstance(inputEl);
                tooltip.enable();
                tooltip.show();
                if (tooltipDebounce) { window.clearTimeout(tooltipDebounce); }
                tooltipDebounce = window.setTimeout(() => {
                    try {
                        tooltip.hide();
                        tooltip.disable();
                        tooltip.dispose();
                    } catch(ex) {
                        logger.error(ex);
                    }
                }, 1500);
            } catch (ex) {
                logger.error(ex);
            }
        });
        return;
    }
    props.filterItem.useAlias = false;
    props.filterItem.selectedValue = pValue;
    props.filterItem.expressionValue = pValue;
    // props.filterItem.operator = props.filterItem.defaultOperator;
    props.filterObject.apply();
}

function getTargetField() {
    return props.filterItem.distinctHandler.distinctTargetColumn ?? props.filterItem.distinctHandler.distinctColumn ?? props.filterItem.distinctHandler.targetColumn ?? props.filterItem.distinctHandler.column;
}

function getDisplayField() {
    return props.filterItem.distinctHandler.distinctColumn ?? (props.filterItem.distinctHandler.targetColumn ? props.filterItem.distinctHandler.column : null) ?? getTargetField();
}

function getDisplay(pSel) {
    if (autocompleteDataObject.value) {
        return pSel[autocompleteDisplayField.value];
    } else {
        return pSel.display;
    }
}

function getValue(pSel) {
    if (autocompleteDataObject.value) {
        return pSel[autocompleteValueField.value];
    } else {
        return pSel.value;
    }
}

function onSelected(pSel) {
    const targetField = getTargetField();
    const displayField = getDisplayField();
    const customFilterFunction = props.filterItem?.options?.filterParams?.autocomplete?.beforeApply;
    if (targetField === displayField) {
        props.filterItem.useAlias = false;
        props.filterItem.operator = 'equals';
        props.filterItem.selectedValue = getValue(pSel);
        props.filterItem.expressionValue = getDisplay(pSel);
    } else {
        props.filterItem.useAlias = true;
        props.filterItem.operator = 'equals';
        props.filterItem.selectedValue = getValue(pSel);
        props.filterItem.expressionValue = getDisplay(pSel);
    }

    if (customFilterFunction) {
        customFilterFunction({
            selected: pSel,
            filterItem: props.filterItem,
            value: getValue(pSel),
            display: getDisplay(pSel)
        })
    }


    props.filterObject.apply();
}

function searchPredicate(a: string, b:string) {
    const lowerA = (a ?? $t('-blank-')).toLocaleLowerCase();
    const lowerB = (b ?? $t('-blank-')).toLocaleLowerCase();
    return lowerA.includes(lowerB);
}

async function getData(pSearchString: string) {
    props.filterItem.distinctHandler.setDataObject(props.filterObject.dataObject);
    const targetField = getTargetField();
    const displayField = getDisplayField();
    const data: any[] = await props.filterItem.distinctHandler.getData();
    return data.filter(x => searchPredicate(x[displayField], pSearchString)).map(item => ({
        display: item[displayField] ?? $t('-blank-'),
        value: item[targetField] ?? $t('-blank-'),
    }));
}

</script>